import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';


const API_KEY = process.env.REACT_APP_CONTENTFUL_API_KEY;

const client = new ApolloClient({
	uri: 'https://graphql.contentful.com/content/v1/spaces/wsxjsme50bbr/environments/master?access_token=' + API_KEY,
	cache: new InMemoryCache()
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ApolloProvider client={client}>
			<App />
		</ApolloProvider>
	</React.StrictMode>
);

