import React from 'react'

const DomainElement = ({ data, isAdmin }) => {
	return (
		<div>
			<strong>URL: {data.url}<br /></strong>
			Kunde: {data.kunde.kundenname}<br /><br />
			<strong>DOMAIN</strong><br />
			Domainanbieter: <a rel="noreferrer" href={data.domainanbieter.url} target="_blank">{data.domainanbieter.name}</a><br />
			Nächste Zahlung: {new Date(data.zahlung).toLocaleString("de-DE", { day: "numeric", year: "numeric", month: "numeric" })}<br />
			Kündigungsfrist: {new Date(data.kuendigungsfrist).toLocaleString("de-DE", { day: "numeric", year: "numeric", month: "numeric" })}<br /><br />
			<strong>HOSTING</strong><br />
			Anbieter: <a rel="noreferrer" href={data.hosting.url} target="_blank">{data.hosting.name}</a><br />
			Nächste Zahlung: {new Date(data.zahlungHosting).toLocaleString("de-DE", { day: "numeric", year: "numeric", month: "numeric" })}<br />
			Kündigungsfrist: {new Date(data.kuendigungsfristHosting).toLocaleString("de-DE", { day: "numeric", year: "numeric", month: "numeric" })}<br /><br /><br />

			{data.pluginsCollection.items.length ? <><strong>Plugins</strong><br/></> : ''}
			{data.pluginsCollection.items.map((item, idx) => {
				return <div key={idx}>
					{item.name}<br />
					Preis: {item.preis}<br />
					Abrechnung: {item.abrechnungsintervall.intervall}<br /><br />
				</div>
			})}
			{isAdmin && <button>EDIT</button>}
			<hr />
		</div>
	)
}

export default DomainElement