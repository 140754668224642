import './App.css';
import { useQuery, gql } from '@apollo/client';
import DomainElement from './Components/DomainElement';
import LoginForm from './Components/LoginForm';
import Header from './Components/Header';
import useLocalStorage from './Hooks/useLocalStorage';

const GET_CONTENT = gql`
	query {domainsCollection(limit: 100){  
	
		items {
			url
		kunde {
			kundenname
		}
		domainanbieter {
			name
			url
		}
		zahlung
		kuendigungsfrist
		hosting {
			name
			url
		}
		zahlungHosting
		kuendigungsfristHosting
		pluginsCollection(limit: 10) {
			items { 
				name
			preis        
			abrechnungsintervall {
				intervall
			}
			}
		}
		}
	}}
`



function App() {
	const { data, loading, error } = useQuery(GET_CONTENT);
	const [user, setUser] = useLocalStorage('user', '')
	
	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error :{error.message}</p>;
	const items = data.domainsCollection.items;
	return (
		<div className="App">
			{user && <Header user={user} setUser={ setUser} />}
			{user?.name ? items.map((item, idx) => {
				return <DomainElement key={idx} data={item} isAdmin={user?.admin} />
			}) :
				<LoginForm
					user={user}
					setUser={setUser}
				/>}
		</div>
	);
}

export default App;
