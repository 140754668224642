import React from 'react'

const Header = ({user, setUser}) => {
	const { name, admin, email } = user
	const handleLogout = () => { 
		setUser('user', '')
	}
	return (
		<header>
			Hallo {name}, {admin ? "ADMIN" : "USER"}, {email}
			<input type="search" value="" placeholder="Suche" onChange={e => console.log(e)} />
			<button onClick={handleLogout}>Logout</button>
		</header>
	)
}

export default Header