import React, { useState } from 'react'



const LoginForm = ({ user, setUser }) => {

	const API_KEY = process.env.REACT_APP_CONTENTFUL_API_KEY;

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')

	
	const fetchUser = async (username, email) => { 
		await fetch(`https://cdn.contentful.com/spaces/wsxjsme50bbr/environments/master/entries?access_token=${API_KEY}&content_type=users&fields.name=${username}&fields.email=${email}&limit=1`).then(
			res => res.json()
		).then(
			data => {
				if (data.items.length > 0) {

					setUser({
						name: data.items[0].fields.name,
						email: data.items[0].fields.email,
						admin: data.items[0].fields.adminrechte
					})
				} else { 
					setUser('user', '')
				}
			}
		).catch(err => console.log(err))

	}

	const handleSubmit = (e) => { 
		e.preventDefault()
		fetchUser(e.target.username.value, e.target.email.value)
	
	}

	return (
		<form onSubmit={handleSubmit}>
			<input type="text" name="username" placeholder="Username" value={name} onChange={e => setName(e.target.value)}></input>
			<input type="text" name="email" placeholder="E-Mail" value={email} onChange={e => setEmail(e.target.value)}></input>
			<button>Login</button>
		</form>
	)
}

export default LoginForm